import React from "react";
import './About.css';

const About = () => {
  return (
    <section
      className="about-section"
      id="about"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15), #000), url("/images/aboutBG.png")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="about-container">
        <h2>About Me</h2>
        <p>
          Welcome to my digital corner! I'm <strong>Pablo Marchán</strong> from Uruguay. I love animals 🐾, and I'm a follower of Christ ✝️. My true passion lies in technology — I enjoy diving into the vast ocean of software development.

          <br /><br />

          I don't limit myself to just one area: I create video games, design in 2D/3D/pixel art, explore artificial intelligence, and build digital tools. I'm also starting to explore literature, which adds a new layer to my creative world.

          <br /><br />

          Programming is my foundation — where ideas become digital realities and take you to new universes. 🌐

          <br /><br />

          Feel free to look around and enjoy this space I built with love and code. 🎉
        </p>

        {/* Íconos de habilidades */}
        <div className="skills-icons">
          <img src="/icons/illustrator.png" alt="Adobe Illustrator" />
          <img src="/icons/premierepro.png" alt="Premiere Pro" />
          <img src="/icons/aftereffects.png" alt="After Effects" />
          <img src="/icons/blender.png" alt="Blender" />
          <img src="/icons/unrealengine.png" alt="Unreal Engine" />
          <img src="/icons/javascript.png" alt="JavaScript" />
          <img src="/icons/python.png" alt="Python" />
          <img src="/icons/cplusplus.png" alt="C++" />
        </div>
      </div>
    </section>
  );
};

export default About;
