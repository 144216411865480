import "./App.css";
import Nabvar from "./Components/Nav/Nav.jsx";
import CardsGames from "./Components/CardsGames/CardsGames.jsx";
import About from "./Components/About/About.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import Posts from "./Components/Posts/Post.jsx";
import { motion } from "framer-motion";
import Reels from "./Components/Reels.jsx";

function App() {
  return (
    <div className="App">
      <Nabvar />

      {/* Sección About con animación */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: false, amount: 0.2 }}
      >
        <About />
      </motion.div>

      {/* Sección CardsGames con animación */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: false, amount: 0.2 }}
      >
        <CardsGames />
      </motion.div>

      {/* Sección Posts con animación */}
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: false, amount: 0.2 }}
      >
        <Posts />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: false, amount: 0.2 }}
      >
        <Reels />
      </motion.div>

      <Footer />
    </div>
  );
}

export default App;

  /*
            ███████╗███████╗████████╗ ██████╗ 
            ██╔════╝██╔════╝╚══██╔══╝██╔═══██╗
            ███████╗█████╗     ██║   ██║   ██║
            ╚════██║██╔══╝     ██║   ██║   ██║
            ███████║███████╗   ██║   ╚██████╔╝ 92
            ╚══════╝╚══════╝   ╚═╝    ╚═════╝ 
                   SETO - Pablo Marchán
                   
           
 */
