import React, { useEffect, useState } from 'react';
import './Posts.css';
import { getDocs, collection } from 'firebase/firestore';
import { db } from "../../service";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);

  useEffect(() => {
    getDocs(collection(db, 'Posts')).then((response) => {
      const postList = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Ordenar por fecha descendente si tenés fechas
      postList.sort((a, b) => new Date(b.date) - new Date(a.date));
      setPosts(postList);
    });
  }, []);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 5);
  };

  return (
    <div id="Posts">
      <h2 className="Textmain">Recent Posts</h2>
      {posts.slice(0, visibleCount).map((post) => (
        <div key={post.id} className="post">
          <h3>{post.title}</h3>
          <p>{post.content}</p>
          <small>Publicado el: {new Date(post.date).toLocaleDateString()}</small>
          {post.videoId && (
            <div className="video">
              <iframe
                width="100%"
                height="400"
                src={`https://www.youtube.com/embed/${post.videoId}`}
                title={`Video relacionado a ${post.title}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      ))}
      {visibleCount < posts.length && (
        <button className="see-more-button" onClick={handleShowMore}>
          Ver más posts
        </button>
      )}
    </div>
  );
};

export default Posts;
