import React, { useEffect, useState } from 'react';
import './CardsGames.css';
import { getDocs, collection } from 'firebase/firestore';
import { db } from "../../service";

const CardsGames = () => {
  const [Products, SetProducts] = useState([]);

  useEffect(() => {
    getDocs(collection(db, 'Proyectos')).then((response) => {
      const games = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      SetProducts(games);
    });
  }, []);

  return (
    <div id="ProjectsGrid">
      {Products.map((producto) => (
        <CardWithLoading key={producto.id} producto={producto} />
      ))}
    </div>
  );
};

const CardWithLoading = ({ producto }) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div className="ModernCard horizontal">
      <div className="image-wrapper">
        {!imgLoaded && <div className="img-skeleton" />}
        <img
          src={producto.IMG}
          alt={producto.NAME}
          onLoad={() => setImgLoaded(true)}
          style={{ display: imgLoaded ? 'block' : 'none' }}
        />
      </div>
      <div className="CardContent">
        <h2>{producto.NAME}</h2>
        <p>{producto.DESCRIPTION}</p>
        <a
          href={producto.LINK}
          target="_blank"
          rel="noopener noreferrer"
          className="pill-button"
        >
          Website
        </a>
      </div>
    </div>
  );
};

export default CardsGames;
