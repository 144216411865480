import React from "react";
import './Reels.css';

const videoList = [
  { id: 'izvUBV9R4BE' },
  { id: 'sczKnVuxx3Q' },
  { id: '6rZJebsl-A4' },
  { id: '-Lo07ha67gs' },
  { id: '0_to7F7sBHU' },
  { id: 'gJ4jFcbDg7g' },
];

const Reels = () => {
  return (
    <section id="ReelsSection">
      <h2 className="reels-title">🎥 My Reels</h2>
      <div className="reels-scroll">
        {videoList.map((video) => (
          <div key={video.id} className="reel-card-horizontal">
            <iframe
              src={`https://www.youtube.com/embed/${video.id}`}
              title={`SETO Reel ${video.id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Reels;
